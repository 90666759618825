/*
*  animation https://animate.style/
*/
import 'animate.css';



/*
 * Welcome to your app's main JavaScript file!
 *
 * We recommend including the built version of this JavaScript file
 * (and its CSS file) in your base layout (base.html.twig).
 */

// any CSS you import will output into a single css file (app.css in this case)
import './styles/app.scss';
// start the Stimulus application
// import './bootstrap'

/*
    Tarte aux citrons
*/

import 'tarteaucitronjs/css/tarteaucitron.css';

/*
    animation activé

*/
document.addEventListener("scroll",function(){
    const img_screen = document.querySelectorAll(".img_screen");
    img_screen.forEach(element => {
        const windowHeight = window.innerHeight;
        const elementHeight = element.getBoundingClientRect().top;
        const element_visible = 50;
        if(elementHeight < windowHeight - element_visible){           
            element.classList.add("animate_img_screen");
        } 
        else
        {            
            element.classList.remove("animate_img_screen");
        } 
    });
})
